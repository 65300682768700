import React, { useEffect, useState } from 'react';
import { db } from '../firebase/base'
import '../styles/style.scss';
import Layout from '../components/layout';
import Seo from '../components/seo';
import '../styles/mes-creations.scss';

export default function MesCreations() {
    const [sites, setSites] = useState([]);
    const collection = "sites";

    useEffect(() => {
        onmousemove = (ev) => {
            const cursor = document.querySelector('#id_cursor')
            const cursorPoint = document.querySelector('#id_cursor_point')

            cursor.style.top = `${ev.pageY}px`
            cursor.style.left = `${ev.pageX}px`
            cursorPoint.style.top = `${ev.pageY}px`
            cursorPoint.style.left = `${ev.pageX}px`
        }
    }, [])

    useEffect(() => {
        db.collection(collection).orderBy("timestamp", "desc").onSnapshot(snapshot => {
            setSites(
                snapshot.docs.map(doc => ({
                    id: doc.id,
                    url: doc.data().url,
                    name: doc.data().name,
                    datecreation: doc.data().datecreation,
                    linksite: doc.data().linksite,
                    description: doc.data().description,
                    firstname: doc.data().firstname
                }))
            )
        })
    }, [])

    console.log(sites)

    return (
        <Layout>
            <Seo title="Mes créations" />
            <span id="id_cursor" className="cursor" />
            <span id="id_cursor_point" />

            <section id="mes_creations">
                <div className="timeline">
                    <ul>
                        {sites.map((site) => {
                            return (
                                <li key={site.id}>
                                    <div className="blurb">
                                        <a href={site.linksite} target="_blank">
                                            <div className="content">
                                                <img src={site.url} />
                                                <h3>{site.name}</h3>
                                                <p className="p_texte">{site.description}</p>
                                            </div></a>
                                        <div className="time">
                                            <p>{site.datecreation}</p>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
            </section>
        </Layout>
    )
}