import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDbc5U0l8ff1Tvp4Xc6hFopKYPncq86EU4",
    authDomain: "pixel-developpement-85405.firebaseapp.com",
    projectId: "pixel-developpement-85405",
    storageBucket: "pixel-developpement-85405.appspot.com",
    messagingSenderId: "751223663785",
    appId: "1:751223663785:web:66dcf4042955f81d960db8"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export { db, firebase as default };